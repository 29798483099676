<template>
  <div class="amount_content_item">
    <span class="iconfont_box">
      <i :class="`iconfont ${icon}`"></i>
    </span>
    <div style="text-align: left;">
      <div>
        <span class="count">{{count}}</span>
        <span class="unit">&nbsp;&nbsp;个</span>
      </div>
      <span class="title">{{title}}</span>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapState, mapMutations, mapActions } from 'vuex'
export default {
  name: 'amountContentItem',
  props: {
    title: { type: String },
    icon: { type: String },
    count: { type: Number }
  },
  data () {
    return {}
  },
  created () { },
  mounted () { },
  computed: {},
  watch: {},
  methods: {}
}
</script>
<style scoped lang="less">
.amount_content_item {
  width: 240px;
  height: 115px;
  background-color: #ffffff;
  box-shadow: 0px 2px 10px 0px rgba(1, 1, 1, 0.1);
  border-radius: 10px;
  display: flex;
  align-items: center;
  .iconfont_box {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 55px;
    height: 55px;
    border-radius: 50%;
    background-color: #fdfdfd;
    box-shadow: inset 0px 0px 5px 0px #e5e5e5;
    margin: 0 20px;
    .iconfont {
      font-size: 20px;
      color: #07a8fb;
    }
  }
  .count {
    font-size: 22px;
    font-weight: 700;
    color: #333333;
  }
  .unit {
    font-size: 16px;
    font-weight: 700;
    color: #333333;
  }
  .title {
    font-size: 16px;
    color: #666666;
    margin-top: 20px;
  }
}
</style>
