<template>
  <!-- 车场 —— 报表统计汇总 -->
  <div class="box-card" style="text-align: left">
    <le-card title="报表统计汇总">
  <!-- 主要内容 -->
  <div class="reportformsCount_box">
      <div class="year_box">
        <span>年份：
          <el-date-picker size="small" prefix-icon="iconfont lebo-rili" v-model="year" format="yyyy 年" type="year" placeholder="选择年" :editable="false" :clearable="false" @change="yearChange">
          </el-date-picker>
        </span>
        <el-tooltip class="item" effect="light" placement="bottom-end">
          <div slot="content" class="content_span">
            <span style="color: #666">总金额 </span><span style="color: #999">
              = 临停车总金额 + 计时卡总金额 + 月租卡总金额 + 储值卡总金额</span><br />
            <span style="color: #666">临停车总金额 </span><span style="color: #999">
              = 临停车金额 + 卡片过期后支付的停车金额 - 退款金额</span><br />
            <span style="color: #666">计时卡总金额 </span><span style="color: #999">
              = 计时卡停车缴费的总金额 - 退款金额</span><br />
            <span style="color: #666">月租卡总金额 </span><span style="color: #999">
              = 新办月租卡金额 + 月租卡续费金额</span><br />
            <span style="color: #666">储值卡总金额 </span><span style="color: #999">
              = 新办储值卡金额 + 储值卡充值金额</span>
          </div>
          <i class="iconfont lebo-shuoming"></i>
        </el-tooltip>
      </div>
      <div class="payment_content_box">
        <div class="payment_content_left">
          <paymentItem color="#33c0d5" title="临停车" :count="summaryPaymentList.temporary_card_payment" :ratio="
              summaryPaymentList.temporary_card_payment /
              summaryPaymentList.total_payment
            "></paymentItem>
          <paymentItem color="#1acd90" title="计时卡" :count="summaryPaymentList.time_card_payment" :ratio="
              summaryPaymentList.time_card_payment /
              summaryPaymentList.total_payment
            "></paymentItem>
        </div>
        <div class="payment_count">
          <div style="margin-top: 30px">
            <span class="total_count">
              {{ summaryPaymentList.total_payment }}</span>
            <span class="total_unit">&nbsp;&nbsp;元</span>
          </div>
          <span class="total">总金额</span>
        </div>
        <div class="payment_content_left reversal">
          <paymentItem color="#e39f28" title="月租卡" :count="summaryPaymentList.month_card_payment" :ratio="
              summaryPaymentList.month_card_payment /
              summaryPaymentList.total_payment
            "></paymentItem>
          <paymentItem color="#f55265" title="储值卡" :count="summaryPaymentList.value_card_payment" :ratio="
              summaryPaymentList.value_card_payment /
              summaryPaymentList.total_payment
            "></paymentItem>
        </div>
      </div>
      <div class="amount_content_box">
        <amountContentItem title="停车场" icon="lebo-lintingche" :count="summaryPaymentList.parking_count"></amountContentItem>
        <amountContentItem title="订单" icon="lebo-dingdan" :count="summaryPaymentList.order_count"></amountContentItem>
        <amountContentItem title="计时卡" icon="lebo-jishika02" :count="summaryPaymentList.time_card_count"></amountContentItem>
        <amountContentItem title="月租卡" icon="lebo-yuezuka" :count="summaryPaymentList.month_card_count"></amountContentItem>
        <amountContentItem title="储值卡" icon="lebo-chuzhika" :count="summaryPaymentList.value_card_count"></amountContentItem>
        <amountContentItem title="贵宾卡" icon="lebo-guibinka01" :count="summaryPaymentList.vip_card_count"></amountContentItem>
      </div>
      <div class="echart_box">
        <leCard class="echart_left" title="缴费统计">
          <div id="bar_box"></div>
        </leCard>
        <leCard class="echart_left" title="支付方式">
          <div id="pie_box"></div>
        </leCard>
      </div>
    </div>
    </le-card>
  </div>
</template>

<script>
import {
  getSummaryPaymentList,
  getSummaryParkingMonthHistogramPaymentList,
  getSummaryParkingPaymentMethondList,
  getSummaryParkingDetailsMonthHistogramPaymentList
} from '@/api/financialStatement'
import * as echarts from 'echarts'
import paymentItem from './components/paymentItem/paymentItem.vue'
import amountContentItem from './components/amountContentItem/amountContentItem.vue'
export default {
  components: {
    paymentItem,
    amountContentItem
  },
  data () {
    return {
      year: new Date(),
      summaryPaymentList: {}
    }
  },
  created () {
    this.fnGetSummaryPaymentList()
    this.fnGetSummaryParkingMonthHistogramPaymentList()
    this.fnGetSummaryParkingPaymentMethondList()
  },
  mounted () { },
  methods: {
    //  获取报表统计汇总统计数
    async fnGetSummaryPaymentList () {
      this.year = this.$moment(this.year).format('YYYY')
      var startTime = this.year + '-01-01 00:00:00'
      const res = await getSummaryPaymentList({
        start_time: startTime
      })
      // console.log('获得财务报表数据', res)
      if (res && res.Code === 200) {
        this.summaryPaymentList = res.Data
      } else {
        this.summaryPaymentList = {}
      }
    },
    //  查看停车场报表统计汇总柱状图
    async fnGetSummaryParkingMonthHistogramPaymentList () {
      var startTime = this.year + '-01-01 00:00:00'
      const res = await getSummaryParkingMonthHistogramPaymentList({
        start_time: startTime
      })
      //  console.log(res);
      if (res && res.Code === 200) {
        var xAxisData = []
        var seriesData = []
        var yAxis
        if (res.Data.length > 0) {
          res.Data.forEach((item) => {
            xAxisData.push(item.str + '月')
            seriesData.push(item.amount)
          })
          yAxis = {
            type: 'value',
            scale: true
            //  min: 'dataMin',
            //  max: "dataMax",
          }
          this.getBarChart(xAxisData, seriesData, yAxis)
        } else {
          xAxisData = [
            '1月',
            '2月',
            '3月',
            '4月',
            '5月',
            '6月',
            '7月',
            '8月',
            '9月',
            '10月',
            '11月',
            '12月'
          ]
          seriesData = []
          yAxis = {
            type: 'value',
            scale: true,
            min: 0,
            max: 1000
          }
          this.getBarChart(xAxisData, seriesData, yAxis)
        }
      }
    },
    async fnGetSummaryParkingPaymentMethondList () {
      const res = await getSummaryParkingPaymentMethondList({
        start_time: this.year + '-01-01 00:00:00'
      })
      //  console.log(res.Data);
      if (res && res.Code === 200) {
        var seriesData = []
        var total = 0
        seriesData.push(
          { value: res.Data.wechat_payment, name: '微信' },
          { value: res.Data.alipay_payment, name: '支付宝' },
          { value: res.Data.cash_payment, name: '现金' },
          { value: res.Data.ETC_payment, name: 'ETC' }
          // { value: res.Data.other_payment, name: '其它' }
        )
        total =
          res.Data.wechat_payment +
          res.Data.alipay_payment +
          res.Data.cash_payment +
          res.Data.ETC_payment
          // + res.Data.other_payment
        this.getPieChart(total, seriesData)
      }
    },
    yearChange () {
      //  console.log(this.year);
      this.fnGetSummaryPaymentList()
      this.fnGetSummaryParkingMonthHistogramPaymentList()
      this.fnGetSummaryParkingPaymentMethondList()
    },
    //  条形统计图
    getBarChart (xAxisData, seriesData, yAxis) {
      var dom = document.getElementById('bar_box')
      if (!dom) return
      //  基于准备好的dom，初始化echarts实例
      var myChart = echarts.init(dom)
      //  绘制图表
      var option = {
        //  标题
        // title: {
        //   text: '单位：元',
        //   textStyle: {
        //     color: '#666666',
        //     fontSize: 14
        //   }
        // },
        tooltip: {
          backgroundColor: '#fff',
          borderColor: '#333',
          textStyle: {
            color: '#333'
          },
          borderWidth: 1,
          show: true,
          formatter: '{b}: {c}元'
        },
        xAxis: {
          type: 'category',
          data: xAxisData,
          axisLabel: {
            interval: 0,
            rotate: 0,
            formatter: function (params, index) {
              return params
            }
          }
        },
        grid: {
          left: '8%',
          right: '1%',
          top: '13%',
          bottom: '8%',
          containLabel: true
        },
        yAxis: yAxis,
        series: [
          {
            data: seriesData,
            type: 'bar',
            barWidth: '15',
            itemStyle: {
              // 柱形图圆角，鼠标移上去效果
              emphasis: {
                barBorderRadius: [10, 10, 10, 10]
              },
              normal: {
                // 柱形图圆角，初始化效果
                barBorderRadius: [10, 10, 10, 10],
                color: new echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    { offset: 0, color: 'rgba(1, 166, 251, 1)' }, // 100% 处的颜色
                    { offset: 0.3, color: 'rgba(1, 166, 251, 0.75)' },
                    { offset: 0.8, color: 'rgba(1, 166, 251, 0.5)' },
                    { offset: 1, color: 'rgba(1, 166, 251, 0.1)' } // 0% 处的颜色
                  ],
                  false
                )
              }
            }
          }
        ]
      }
      myChart.clear()
      myChart.setOption(option)
      var _this = this
      myChart.off('click')
    },
    fnDetailBar (sourceData) {
      var dom = document.getElementById('bar_dialog_box')
      if (!dom) return
      var myChart = echarts.init(dom)
      var option = {
        tooltip: {
          backgroundColor: '#fff',
          borderColor: '#333',
          borderWidth: 1,
          trigger: 'axis',
          formatter: function (data, i) {
            return `${data[0].axisValueLabel}：${data[0].data[2]} 元`
          }
        },
        dataset: [
          {
            dimensions: ['parking_name', 'parking_id', 'amount'],
            source: sourceData
          },
          {
            transform: {
              type: 'sort',
              config: { dimension: 'amount', order: 'desc' }
            }
          }
        ],
        xAxis: {
          type: 'category',
          axisLabel: { interval: 0, rotate: 45 }
        },
        grid: {
          left: '5%',
          right: '5%',
          top: '10%',
          bottom: '25%'
        },
        dataZoom: [
          // 区域缩放
          {
            show: true, // 是否显示 组件。如果设置为 false，不会显示，但是数据过滤的功能还存在。
            type: 'slider', // slider表示有滑动块的，inside表示内置的
            filterMode: 'empty',
            xAxisIndex: 0, // 设置 dataZoom-inside 组件控制的 x轴,可以用数组表示多个轴
            start: 0, // 数据窗口范围的起始百分比,表示30%
            end: 25, // 数据窗口范围的结束百分比,表示70%
            orient: 'horizontal', // 布局方式是横还是竖。不仅是布局方式，对于直角坐标系而言，也决定了，缺省情况控制横向数轴还是纵向数轴。'horizontal'：水平。'vertical'：竖直。
            zoomLock: true, // 是否锁定选择区域（或叫做数据窗口）的大小。如果设置为 true 则锁定选择区域的大小，也就是说，只能平移，不能缩放。
            left: 'center', // 组件离容器左侧的距离,'left', 'center', 'right','20%'
            top: 'top', // 组件离容器上侧的距离,'top', 'middle', 'bottom','20%'
            right: 'auto', // 组件离容器右侧的距离,'20%'
            bottom: 'auto' // 组件离容器下侧的距离,'20%'
          }
        ],
        yAxis: {},
        series: {
          type: 'bar',
          encode: { x: 'parking_name', y: 'amount' },
          datasetIndex: 1,
          barMaxWidth: 50,
          barWidth: '15',
          itemStyle: {
            // 柱形图圆角，鼠标移上去效果
            emphasis: {
              barBorderRadius: [10, 10, 10, 10]
            },
            normal: {
              // 柱形图圆角，初始化效果
              barBorderRadius: [10, 10, 10, 10],
              color: new echarts.graphic.LinearGradient(
                0,
                0,
                0,
                1,
                [
                  { offset: 0, color: 'rgba(1, 166, 251, 1)' }, // 100% 处的颜色
                  { offset: 0.3, color: 'rgba(1, 166, 251, 0.75)' },
                  { offset: 0.8, color: 'rgba(1, 166, 251, 0.5)' },
                  { offset: 1, color: 'rgba(1, 166, 251, 0.1)' } // 0% 处的颜色
                ],
                false
              )
            }
          }
        }
      }
      myChart.clear()
      myChart.setOption(option)
    },
    //  扇形统计图
    getPieChart (total, seriesData) {
      var dom = document.getElementById('pie_box')
      if (!dom) return
      //  基于准备好的dom，初始化echarts实例
      var myChart1 = echarts.init(dom)
      var colorList = ['#33c0d5', '#1acd90', '#e39f28', '#f55265', '#5d77e8']
      //  绘制图表
      var option1 = {
        color: colorList,
        title: [
          {
            // 第一个圆环标题
            text: '总缴费:', // 主标题
            textStyle: {
              // 副标题样式
              color: '#666666',
              fontSize: 14,
              fontWeight: '700'
            },
            left: '35%', // 定位到适合的位置
            bottom: '0%', // 定位到适合的位置
            textAlign: 'center' // 主、副标题水平居中显示
          }, {
            text: total.toFixed(2) + '元', // 主标题
            textStyle: {
              // 主标题样式
              color: '#01a6fb',
              fontSize: 14,
              fontWeight: '700'
            },
            left: '41%', // 定位到适合的位置
            bottom: '0%', // 定位到适合的位置
            textAlign: 'left' // 主、副标题水平居中显示
          }
          // {
          //   // 第一个圆环标题
          //   text: total.toFixed(2) + ' 元', // 主标题
          //   textStyle: {
          //     // 主标题样式
          //     color: '#01a6fb',
          //     fontSize: 16,
          //     fontWeight: '700'
          //   },
          //   left: '40%', // 定位到适合的位置
          //   top: '40%', // 定位到适合的位置
          //   subtext: '总缴费', // 副标题
          //   subtextStyle: {
          //     // 副标题样式
          //     color: '#666666',
          //     fontSize: 14
          //   },
          //   textAlign: 'center' // 主、副标题水平居中显示
          // }
        ],
        legend: {
          // 强制设置图形为圆。
          icon: 'circle',
          orient: 'vertical',
          top: 'center',
          right: '5%',
          textStyle: {
            color: '#666666',
            fontSize: 14,
            align: 'left',
            verticalAlign: 'middle',
            rich: {
              a0: {
                color: colorList[0]
              },
              a1: {
                color: colorList[1]
              },
              a2: {
                color: colorList[2]
              },
              a3: {
                color: colorList[3]
              },
              a4: {
                color: colorList[4]
              }
            }
          },
          data: seriesData,
          formatter: (params) => {
            const inx = seriesData.findIndex((item) => item.name === params)
            // console.log(seriesData);
            // console.log('---', params);
            return `{name|${params}：}{a${inx}|${seriesData[inx].value}  元}`
          }
        },
        tooltip: {
          trigger: 'item',
          backgroundColor: '#fff',
          borderColor: '#333',
          borderWidth: 1,
          textStyle: {
            width: 40,
            color: 'black'
          },
          formatter: function (params) {
            var tip = ''
            if (params.seriesIndex === 0) {
              tip = `<div style="text-align: left;">${params.name}：<span style="font-weight: 700;color:${params.color};">缴费${params.value}元</span></div>
              <div style="text-align: left;">占比：<span style="font-weight: 700;color:${params.color};">${params.percent}%</span></div>`
            } else {
              tip = ''
            }
            return tip
          }
        },
        series: [
          // 环形展示层
          {
            type: 'pie',
            color: colorList,
            left: '1%',
            radius: ['40%', '60%'],
            center: ['40%', '50%'],
            label: {
              show: false
            },
            data: seriesData
          },
          // 内部设置
          {
            type: 'pie',
            left: '1%',
            radius: ['30%', '0%'],
            center: ['40%', '50%'],
            hoverAnimation: false,
            clockWise: false,
            itemStyle: {
              normal: {
                shadowBlur: 10,
                shadowColor: 'rgba(1, 1, 1, 0.1)',
                color: '#fff'
              }
            },
            label: {
              show: false
            },
            data: [100]
          },
          // 边框
          {
            type: 'pie',
            left: '1%',
            radius: ['70%', '69%'],
            center: ['40%', '50%'],
            hoverAnimation: false,
            clockWise: false,
            itemStyle: {
              normal: {
                shadowBlur: 20,
                shadowColor: 'rgba(1, 166, 251, 0.3)',
                borderColor: '#fff'
              }
            },
            label: {
              show: false
            },
            data: [100]
          }
        ]
      }
      myChart1.clear()
      myChart1.setOption(option1)
    }
  }
}
</script>

<style scoped lang="less">
.box-card{
  margin-top: 10px;
}
.reportformsCount_box {
  /deep/ .year_box {
    margin-top: 20px;
    height: 33px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
    box-sizing: border-box;
    .el-date-editor {
      width: 200px;
      height: 33px;
      .el-input__inner {
        width: inherit;
        height: inherit;
      }
      .el-input__prefix {
        left: 170px;

        height: inherit;
        display: flex;
        align-items: center;
        justify-content: center;
        .el-input__icon {
          font-size: 16px;
        }
      }
    }
    .iconfont {
      font-size: 30px;
    }
    .content_span {
      font-size: 12px;
      color: #999999;
      span{
      font-size: 12px;
      }
    }
  }
  .payment_content_box {
    display: flex;
    justify-content: space-between;
    padding: 20px 10px;
    overflow: hidden;
    .payment_content_left {
      width: 630px;
      height: 200px;
      background-image: url("~@/assets/images/total-left.png");
      background-repeat: no-repeat;
      background-size: 100%;
      background-position: center;
      display: flex;
      align-items: center;
      padding-left: 0px;
      box-sizing: border-box;
    }
    .reversal {
      background-image: url("~@/assets/images/total-right.png");
      padding-left: 30px;
    }
    .payment_count {
      width: 310px;
      height: 200px;
      background-image: url("~@/assets/images/totalCount.png");
      background-repeat: no-repeat;
      background-size: 100%;
      background-position: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      .total_count {
        font-size: 28px;
        font-weight: 700;
        color: #01a6fb;
      }
      .total_unit {
        font-size: 20px;
        font-weight: 700;
        color: #01a6fb;
      }
      .total {
        font-size: 18px;
        font-weight: 700;
        color: #666666;
        margin-top: 20px;
      }
    }
  }
  .amount_content_box {
    width: 100%;
    padding: 0 15px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .echart_box {
    padding: 20px;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    .echart_left {
      width: 49%;
      height: 100%;
      #pie_box,
      #bar_box {
        width: 100%;
        height: 230px;
      }
    }
  }
}
#bar_dialog_box {
  min-width: 100%;
  min-height: 500px;
}
</style>
