<template>
  <div class="payment_item_box" :style="`border-left: 5px solid ${color};`">
    <span class="title">{{ title }}</span>
    <div>
      <span class="count">{{ count?count.toFixed(2) : '0.00'}}</span>
      <span class="unit">&nbsp;&nbsp;元</span>
    </div>
    <span class="ratio">占比：{{ratio?  (ratio * 100).toFixed(2):'0.00'}}%</span>
    <i class="iconfont lebo-quxian" :style="`color: ${color};`"></i>
  </div>
</template>
<script>
import { mapGetters, mapState, mapMutations, mapActions } from 'vuex'
export default {
  name: 'paymentItem',
  props: {
    color: {
      type: String
    },
    title: {
      type: String
    },
    count: {
      type: Number
    },
    ratio: {
      type: Number
    }
  },
  data () {
    return {}
  },
  created () {},
  mounted () {},
  computed: {},
  watch: {},
  methods: {}
}
</script>
<style scoped lang="less">
.payment_item_box {
  width: 237px;
  height: 128px;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  padding: 0 20px;
  margin-left: 40px;
  .title {
    font-size: 16px;
    color: #666666;
    margin-bottom: 10px;
  }
  .count {
    font-size: 22px;
    font-weight: 700;
    color: #333333;
    margin-bottom: 10px;
  }
  .unit {
    font-size: 16px;
    font-weight: 700;
    color: #333333;
  }
  .ratio {
    font-size: 16px;
    color: #666666;
  }
  .iconfont.iconquxian {
    position: absolute;
    bottom: 8px;
    right: 8px;
    text-shadow: 0px 4px 2px rgb(150, 150, 150, 0.1);
  }
}
</style>
